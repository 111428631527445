const VaultIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill="currentColor"
      className="bi bi-safe"
      viewBox="0 0 16 16"
    >
      <path d="M1 1.5A1.5 1.5 0 012.5 0h12A1.5 1.5 0 0116 1.5v13a1.5 1.5 0 01-1.5 1.5h-12A1.5 1.5 0 011 14.5V13H.5a.5.5 0 010-1H1V8.5H.5a.5.5 0 010-1H1V4H.5a.5.5 0 010-1H1V1.5zM2.5 1a.5.5 0 00-.5.5v13a.5.5 0 00.5.5h12a.5.5 0 00.5-.5v-13a.5.5 0 00-.5-.5h-12z" />
      <path d="M13.5 6a.5.5 0 01.5.5v3a.5.5 0 01-1 0v-3a.5.5 0 01.5-.5zM4.828 4.464a.5.5 0 01.708 0l1.09 1.09a3.003 3.003 0 013.476 0l1.09-1.09a.5.5 0 11.707.708l-1.09 1.09c.74 1.037.74 2.44 0 3.476l1.09 1.09a.5.5 0 11-.707.708l-1.09-1.09a3.002 3.002 0 01-3.476 0l-1.09 1.09a.5.5 0 11-.708-.708l1.09-1.09a3.003 3.003 0 010-3.476l-1.09-1.09a.5.5 0 010-.708zM6.95 6.586a2 2 0 102.828 2.828A2 2 0 006.95 6.586z" />
    </svg>
  );
};

export default VaultIcon;
